import { ArrowRightIcon, ExternalLinkIcon } from 'lucide-react';

import { Heading } from '@/components/heading';
import { Image } from '@/components/image';
import { Link } from '@/components/link';
import { Text } from '@/components/text';
import { Pill } from '@/components/ui/pill';
import { POST_TYPES } from '@/lib/constants';
import { AllPostsPostInterface, PostInterface } from '@/types/cms-types';
import { formatDateForUser, tryParseDate } from '@/utils/format/date-time';
import { getPostUrl } from '@/utils/helpers/urls';
import { transformPostType, truncateWords } from '@/utils/strings';
import { tv } from '@/utils/styles';

interface PostCardInterface {
  post: AllPostsPostInterface;
  variant?: PostInterface['postType'];
  className?: string;
}

const PostCard = ({ post, variant, className }: PostCardInterface) => {
  const postCardUrl = post.externalUrl || getPostUrl(post);

  const {
    base,
    inner,
    meta,
    metaInner,
    image,
    postType,
    content,
    heading,
    footer,
    dateLabel,
    contentWrapper,
    pillStyles,
    readMore,
    readMoreInner,
    readMoreIcon,
    contentMeta,
  } = styles({ variant });

  const date = tryParseDate(post.publishedAt);

  return (
    <Link href={postCardUrl} {...(post.externalUrl ? { target: '_blank' } : {})} className={base({ className })}>
      <div className={inner()}>
        {variant === POST_TYPES.PRESS && (
          <div className={meta()}>
            <div className={metaInner()}>
              <Text variant="label" className={postType()}>
                {post.publicationName || transformPostType(post.postType)}
              </Text>
              <Text variant="label" className={dateLabel()}>
                {date ? formatDateForUser(date) : ''}
              </Text>
            </div>
            {post.postTopic && <Pill label={post.postTopic.label} className="inline-flex md:mt-2" size="small" />}
          </div>
        )}

        <div className={image()}>
          {post.image && <Image data={post.image} layout="fill" objectFit="cover" objectPosition="50% 50%" />}
        </div>

        <div className={contentWrapper()}>
          <div className={content()}>
            {variant === POST_TYPES.CASE_STUDIES && post.companyLogo && (
              <Image data={post.companyLogo} className="mb-6 max-w-[120px]" />
            )}
            {variant === POST_TYPES.BLOG && (
              <div className={contentMeta()}>
                <Text variant="label" className={dateLabel()}>
                  {date ? formatDateForUser(date) : ''}
                </Text>
                {post.postTopic && <Pill label={post.postTopic.label} className={pillStyles()} />}
              </div>
            )}
            <Heading variant="h4" className={heading()}>
              {post.title}
            </Heading>
            {variant === POST_TYPES.PRESS && <Text variant="secondary">{post.summary}</Text>}
            {variant === POST_TYPES.BLOG && <Text className="leading-6">{truncateWords(post.summary, 20)}</Text>}
          </div>

          <div className={footer()}>
            <Text variant="secondary" className={readMore()}>
              <span className={readMoreInner()}>
                <span>Read more</span>{' '}
                {variant === POST_TYPES.PRESS ? (
                  <ExternalLinkIcon size={16} className={readMoreIcon()} />
                ) : (
                  <ArrowRightIcon size={16} className={readMoreIcon()} />
                )}
              </span>
            </Text>
          </div>
        </div>
      </div>
    </Link>
  );
};

const styles = tv({
  slots: {
    base: 'group block h-full w-full overflow-hidden transition-all duration-200 ease-in-out',
    inner: 'flex h-full',
    meta: '',
    metaInner: '',
    image: 'relative',
    content: '',
    heading:
      'post-card-heading mb-2.5 text-blue-500 transition-all duration-200 ease-in-out group-hover:text-lightBlue-600',
    footer: 'mt-auto',
    postType: 'mb-2 mr-6 text-sm font-bold md:mr-0',
    dateLabel: 'mb-1 text-sm text-grey-600',
    contentWrapper: 'flex h-full flex-col',
    contentMeta: 'flex min-h-9 justify-between',
    pillStyles: '!-mt-2 !mb-2.5',
    readMore: 'mb-0 text-base font-medium',
    readMoreInner: 'flex items-center text-lightBlue-600',
    readMoreIcon: 'ml-1.5 group-hover:animate-bounce-x',
  },
  variants: {
    variant: {
      [POST_TYPES.BLOG]: {
        base: 'rounded-lg border border-grey-300 bg-white',
        inner: 'flex-col',
        contentWrapper: 'p-6',
        image: 'h-0 shrink-0 bg-grey-300 pb-[62%]',
      },
      [POST_TYPES.CASE_STUDIES]: {
        base: 'rounded-lg bg-grey-100',
        inner: 'flex-col',
        content: 'mt-6 px-6',
        image: 'h-0 shrink-0 bg-grey-300 pb-[62%]',
        footer: 'px-6 pb-6',
      },
      [POST_TYPES.PRESS]: {
        base: 'border-t border-grey-300 bg-white py-8',
        inner: 'w-full flex-col md:flex-row',
        image: 'hidden',
        meta: 'shrink-0 max-lg:mb-4 max-lg:mr-6 md:block lg:basis-[256px]',
        metaInner: 'flex-row gap-2 max-md:flex',
        content: 'max-w-3xl md:pr-6',
      },
    },
  },
});

export { PostCard };
