import { isFunction } from 'lodash';
import { CheckCircle2 } from 'lucide-react';
import { tv } from 'tailwind-variants';

import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { SubscribeButton } from '@/components/modules/subscriptions/subscribe-button';
import { Text } from '@/components/text';
import { SubscriptionPlan, SubscriptionPrice } from '@/types';
import {
  checkIsFreePlan,
  getPlanInclusions,
  getPlanIntervalString,
  getPlanPrice,
  getPlanPriceString,
} from '@/utils/subscriptions';

interface Props {
  plan: SubscriptionPlan;
  showInclusions?: boolean;
  showAnnualPrice?: boolean;
  showButtons?: boolean;
  isHighlighted?: boolean;
  highlightText?: string;
  isActivePlan?: boolean;
  isSelectable?: boolean;
  subscriptionsEnabled?: boolean;
  buttonText?: string;
  onShowInclusions: (val: boolean) => void;
  onSelect?: (price: SubscriptionPrice) => void;
}

const PlanItem = ({
  plan,
  showInclusions,
  showAnnualPrice = false,
  showButtons = true,
  isHighlighted = false,
  highlightText = 'Current plan',
  isActivePlan = false,
  isSelectable = false,
  subscriptionsEnabled = true,
  buttonText = 'Sign up now',
  onShowInclusions,
  onSelect,
}: Props) => {
  const inclusionsArr = getPlanInclusions(plan);
  const planPrice = getPlanPrice(plan, showAnnualPrice);
  const isFreePlan = checkIsFreePlan(plan);

  const handleSelectPlan = () => {
    if (isSelectable && isFunction(onSelect) && planPrice) {
      onSelect(planPrice);
    }
  };

  const getButtonText = () => {
    if (isFreePlan) return 'Join for free';

    return buttonText;
  };

  const {
    base,
    headingWrapper,
    heading,
    highlightedMessage,
    text,
    planPriceHeading,
    annualPrice,
    inclusionsButton,
    inclusionText,
    currencyText,
    inclusionItem,
  } = planItemStyles({
    isClickable: isSelectable,
    isSelected: isHighlighted,
  });

  return (
    <div
      tabIndex={isSelectable ? 0 : undefined}
      role="button"
      className={base()}
      onClick={() => handleSelectPlan()}
      onKeyUp={({ key }) => [' ', 'Enter'].includes(key) && handleSelectPlan()}
    >
      <div className="max-lg:pb-5 lg:min-h-32">
        <div className={headingWrapper()}>
          <Heading variant="h3" as="h3" className={heading()}>
            {plan.name}
          </Heading>
          {isHighlighted && <div className={highlightedMessage()}>{highlightText}</div>}
        </div>
        <Text variant="secondary" className={text()}>
          {plan.description}
        </Text>
      </div>
      {planPrice && (
        <div>
          <Heading as="h4" className={planPriceHeading()} key={planPrice.id}>
            {getPlanPriceString(planPrice, showAnnualPrice)}
            {!isFreePlan && <small className={currencyText()}>{planPrice.currency}</small>}
          </Heading>
          <Text className={text({ className: 'text-baseSm' })}>
            {getPlanIntervalString(planPrice, showAnnualPrice)}
          </Text>
        </div>
      )}
      {showAnnualPrice && !isFreePlan && <span className={annualPrice()}>15% off</span>}
      {showButtons && (
        <div className="mt-4">
          <SubscribeButton
            variant={isFreePlan ? 'primaryLightOutline' : 'primaryLight'}
            plan={plan}
            buttonText={isActivePlan ? 'Active plan' : getButtonText()}
            buttonDisabled={isActivePlan}
            fullWidth
            annualPriceSelected={showAnnualPrice}
            subscriptionsEnabled={subscriptionsEnabled}
          />
        </div>
      )}
      {!showInclusions && (
        <button type="button" className={inclusionsButton()} onClick={() => onShowInclusions(true)}>
          See what&apos;s included <Icon name="down" size="base" className="ml-xs" />
        </button>
      )}
      {showInclusions && inclusionsArr && (
        <div className="mt-6">
          <Text className={inclusionText()}>What&apos;s included</Text>
          <ul className="text-sm">
            {inclusionsArr.map((item: string, index: number) => (
              <li className={inclusionItem()} key={`item-${index}`}>
                <CheckCircle2 className="stroke-green-500" size={16} />
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export const planItemStyles = tv({
  slots: {
    base: 'relative flex w-full cursor-default flex-col rounded-md border border-grey-200 bg-white p-8 pb-6 text-left shadow-md',
    headingWrapper: 'mb-2 flex items-center',
    heading: 'm-0 font-semibold',
    highlightedMessage:
      'absolute -left-[0.3rem] -right-[0.3rem] -top-9 inline-block rounded-tl-md rounded-tr-md bg-lightBlue-500 p-2 text-center text-base font-medium leading-6 text-white',
    text: 'm-0 text-grey-800',
    planPriceHeading: 'my-0 text-lg font-semibold leading-ultraTight',
    annualPrice:
      'absolute right-4 top-4 inline-block rounded-md bg-yellow px-2 py-1 text-sm font-semiBold leading-snug',
    inclusionsButton:
      'mt-4 flex items-center text-left text-sm text-text-secondary [&_svg]:fill-text-secondary hover:[&_svg]:fill-lightBlue-600',
    inclusionText: 'mb-2 text-baseSm font-medium',
    inclusionItem: 'mb-2 flex gap-1',
    currencyText: 'ml-1 text-baseSm uppercase',
  },
  variants: {
    isSelected: {
      true: {
        base: 'mt-8 shadow-solid !shadow-lightBlue-500 lg:mt-0',
      },
    },
    isClickable: {
      true: {
        base: 'cursor-pointer hover:border-lightBlue-500 hover:outline hover:outline-1 hover:outline-lightBlue-500',
      },
    },
  },
});

export { PlanItem };
