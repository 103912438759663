import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

import { Icon } from '@/components/icon';
import { RadioTabs } from '@/components/inputs/radio-tabs';
import { Link } from '@/components/link';
import { PlanItem } from '@/components/modules/plan/item';
import { PlanSkeleton } from '@/components/modules/plan/skeleton';
import { SubscribeButton } from '@/components/modules/subscriptions/subscribe-button';
import { PromoText } from '@/components/promo-text';
import { Text } from '@/components/text';
import { UserMessage } from '@/components/ui/user-message';
import { useFeatureFlag } from '@/hooks/use-feature-flag';
import { useSubscriptionPlans } from '@/hooks/use-subscription-plans';
import { FEATURE_FLAGS } from '@/lib/constants/feature-flags';
import { marketingPages } from '@/lib/constants/marketing-site';
import { SubscriptionPlan, SubscriptionPrice } from '@/types';
import { getUserIsOnPaidTier } from '@/utils/permissions/helpers';
import { cn, tv } from '@/utils/styles';
import { checkIsFreePlan, checkIsTeamPlan, findPlanById, getDefaultSubscriptionPlan } from '@/utils/subscriptions';
import { trackUser } from '@/utils/tracking';

export interface Props {
  showInclusions?: boolean;
  showButtons?: boolean;
  showEnterprise?: boolean;
  allowPlanSelection?: boolean;
  activePlanText?: string;
  align?: 'center' | 'left';
  shouldHideFreePlan?: boolean;
  className?: string;
}

const PlanListing = ({
  showInclusions: defaultShowInclusions,
  showButtons = true,
  showEnterprise = true,
  allowPlanSelection = false,
  activePlanText = 'Current plan',
  align = 'center',
  shouldHideFreePlan = false,
  className,
}: Props) => {
  const { isLoading, plans, error } = useSubscriptionPlans();
  const [showAnnualPrice, setShowAnnualPrice] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>(undefined);
  const [showInclusions, setShowInclusions] = useState(defaultShowInclusions);
  const [shouldShowButtons, setShouldShowButtons] = useState(false);
  const { data: session } = useSession();
  const { variant: isAnnualSubscriptionEnabled } = useFeatureFlag(FEATURE_FLAGS.ANNUAL_SUBSCRIPTIONS);

  // Buttons are hidden if user is already on a paid plan
  const buttonText = session ? 'Upgrade now' : 'Sign up now';
  const userIsOnPaidPlan = session ? getUserIsOnPaidTier(session.subscription) : false;

  const selectedPlan = selectedPlanId ? findPlanById(selectedPlanId, plans) : null;

  const handleSelectPlan = (price: SubscriptionPrice) => {
    setSelectedPlanId(price.planId);
    trackUser.event('Select Plan', {
      planId: price.planId,
      priceId: price.id,
    });
  };

  const handleOnChangeInterval = (showAnnualBilling: boolean) => {
    setShowAnnualPrice(showAnnualBilling);
    // Event tracking
    trackUser.event('Toggle Plan Interval', { annualPrice: showAnnualBilling });
    if (showAnnualBilling) {
      trackUser.conversion({ category: 'Plans', action: 'ViewedAnnualBilling' });
    }
  };

  useEffect(() => {
    if (session) {
      // If user is on a free plan, use default 'free' plan
      const defaultPlan = getDefaultSubscriptionPlan(plans);
      const activePlanId = session?.subscription?.planId || defaultPlan?.id;
      setSelectedPlanId(activePlanId);
    }
  }, [session, plans]);

  useEffect(() => {
    // Weird hacky way of setting buttons to visible to avoid
    // hydration issues with Features loading from localstorage
    // @TODO Remove once subscriptions are on permanently
    setShouldShowButtons(!userIsOnPaidPlan && showButtons);
  }, [showButtons, userIsOnPaidPlan]);

  const getIsHighlighted = (plan: SubscriptionPlan): boolean => {
    if (selectedPlan) {
      return plan.id === selectedPlanId;
    }

    return checkIsTeamPlan(plan);
  };

  const getHighlightText = () => (selectedPlan ? activePlanText : 'Most popular');

  const { radioTabs, plansWrapper, enterpriseWrapper, footerWrapper, footerTextWrapper, text } = styles({
    shouldHideFreePlan,
  });

  if (error) return <UserMessage variant="error" heading={error} />;

  return (
    <div>
      {isAnnualSubscriptionEnabled && (
        <div className={radioTabs({ className })} style={{ justifyContent: align }}>
          <RadioTabs
            name="toggle-subscription-interval"
            onChange={handleOnChangeInterval}
            selected={showAnnualPrice}
            options={[
              {
                label: 'Monthly billing',
                value: false,
              },
              {
                label: (
                  <PromoText
                    parent={<span>Annual billing</span>}
                    label="15% discount"
                    offset={{ crossAxis: -52, mainAxis: 20 }}
                  />
                ),
                value: true,
              },
            ]}
          />
        </div>
      )}
      <div className={plansWrapper()}>
        {Boolean(isLoading && !plans.length) && (
          <>
            <PlanSkeleton showInclusions={showInclusions} />
            <PlanSkeleton showInclusions={showInclusions} />
            <PlanSkeleton showInclusions={showInclusions} />
            <PlanSkeleton showInclusions={showInclusions} />
          </>
        )}
        {plans.map((plan) => {
          if (shouldHideFreePlan && checkIsFreePlan(plan)) {
            return null;
          }

          const isHighlighted = getIsHighlighted(plan);
          return (
            <PlanItem
              key={plan.id}
              plan={plan}
              showInclusions={showInclusions}
              showAnnualPrice={showAnnualPrice}
              showButtons={shouldShowButtons}
              buttonText={buttonText}
              isHighlighted={isHighlighted}
              isActivePlan={selectedPlanId === plan.id}
              isSelectable={allowPlanSelection}
              highlightText={getHighlightText()}
              onShowInclusions={setShowInclusions}
              onSelect={handleSelectPlan}
            />
          );
        })}
        {Boolean(!plans?.length && !isLoading) && <Text>Plans are not available at the moment</Text>}
      </div>
      {allowPlanSelection && (
        <div className={footerWrapper()}>
          {selectedPlan && (
            <SubscribeButton
              plan={selectedPlan}
              buttonText="Confirm & continue"
              buttonDisabled={!selectedPlan}
              annualPriceSelected={showAnnualPrice}
              showArrow
            />
          )}
          {selectedPlan && selectedPlan?.name !== 'Starter' ? (
            <div className={footerTextWrapper()}>
              <Icon name="lock" size="base" color="text.secondary" className="mr-xs" />
              <Text variant="secondary" className={text()}>
                You will be redirected to a secure checkout page to finalise your subscription payment.
              </Text>
            </div>
          ) : (
            <Text variant="secondary" className={text({ className: 'mt-4' })}>
              You can upgrade your account at any time if you change your mind.
            </Text>
          )}
          <Text variant="secondary" className={text({ className: 'mt-1 text-sm' })}>
            By signing up for one of our accounts you agree to the policies outlined in our{' '}
            <Link href={marketingPages.terms.url} target="_blank">
              {marketingPages.terms.label}
            </Link>{' '}
            and{' '}
            <Link href={marketingPages.privacy.url} target="_blank">
              {marketingPages.privacy.label}
            </Link>
            .
          </Text>
        </div>
      )}
      {showEnterprise && (
        <div className={enterpriseWrapper()}>
          <Text variant="secondary" className="m-0">
            Can&apos;t find a suitable plan? We offer enterprise solutions that can give you better flexibility and
            access options -{' '}
            <Link className={linkStyles} href={marketingPages.contact.url}>
              Request a Quote
            </Link>
          </Text>
          <Text variant="secondary" className="m-0 mt-4">
            Have a question about our plans? -{' '}
            <Link className={linkStyles} href={marketingPages.contact.url}>
              Contact us
            </Link>
          </Text>
        </div>
      )}
    </div>
  );
};

const styles = tv({
  slots: {
    radioTabs: 'mb-24 flex',
    plansWrapper:
      'grid grid-cols-1 content-center justify-items-center gap-x-4 gap-y-4 md:grid-cols-2 lg:auto-cols-fr lg:grid-flow-col-dense lg:justify-items-start',
    enterpriseWrapper: 'mt-12 border-y border-grey-200 p-6 text-center',
    footerWrapper: 'mt-8 rounded-md border border-grey-100 bg-grey-50 p-6',
    footerTextWrapper: 'mt-4 flex items-center',
    text: 'm-0 text-text-secondary',
  },
  variants: {
    shouldHideFreePlan: {
      true: {
        plansWrapper: 'lg:grid-cols-3 lg:px-0',
        radioTabs: 'mb-12',
      },
    },
  },
});

const linkStyles = cn('decoration font-medium text-lightBlue-600 no-underline hover:underline');

export { PlanListing };
