import * as yup from 'yup';

import { SHIPMENT_IMPORT_VALIDATION_MESSAGES } from '@/components/modules/shipment/import/constants';
import { ImageWithFallback } from '@/components/ui/image-with-fallback';
import { API_ERROR, SHIPMENT_FORM_FIELDS, SHIPMENT_TRACKING_TYPES, TRACKING_TYPE } from '@/lib/constants';
import { MailCarrierModel } from '@/lib/models/mail-carrier/types';
import { TrackingNumberType } from '@/lib/models/shipment/types';
import { CarrierSearchInterface, OptionGroup, OptionItem } from '@/types';
import { SegmentModeType } from '@/types/api-types';
import { validateAwbNumber } from '@/utils/shipments/validators/awb';
import { validateContainerId } from '@/utils/shipments/validators/container';
import { validateMbl } from '@/utils/shipments/validators/mbl';

interface OptionItemWithAdvancedTracking extends OptionItem {
  requiresAdvancedTracking?: boolean;
}

interface CarrierOption {
  label: string;
  value: string;
  type: SegmentModeType;
  id: string;
}

export const shipmentCreateFormIntermodalValidationSchema = yup
  .object({
    [SHIPMENT_FORM_FIELDS.TRACKING_NUMBER_TYPE]: yup.string().required('Tracking number type is required'),
    [SHIPMENT_FORM_FIELDS.TRACKING_NUMBER]: yup.string().when(SHIPMENT_FORM_FIELDS.TRACKING_NUMBER_TYPE, {
      is: SHIPMENT_TRACKING_TYPES.CONTAINER_NUMBER,
      then: yup
        .string()
        .test('is-valid-container-id', SHIPMENT_IMPORT_VALIDATION_MESSAGES.CONTAINER_NUMBER, (value) =>
          validateContainerId(value),
        ),
      otherwise: yup.string().required('Tracking number is required'),
    }),
  })
  .required();

export const shipmentCreateFormAirValidationSchema = yup
  .object({
    [SHIPMENT_FORM_FIELDS.TRACKING_NUMBER]: yup
      .string()
      .required('Tracking number is required')
      .test('is-valid-awb', SHIPMENT_IMPORT_VALIDATION_MESSAGES.AWB_NUMBER, (value) => validateAwbNumber(value)),
  })
  .required();

export const shipmentCreateFormParcelValidationSchema = yup
  .object({
    [SHIPMENT_FORM_FIELDS.TRACKING_NUMBER]: yup.string().required('Tracking number is required'),
    [SHIPMENT_FORM_FIELDS.CARRIER]: yup.string().required('Carrier is required'),
  })
  .required();

export const INTERMODAL_SHIPMENT_TYPE_OPTIONS: OptionItemWithAdvancedTracking[] = [
  {
    value: SHIPMENT_TRACKING_TYPES.CONTAINER_NUMBER,
    label: 'Container ID',
  },
  {
    value: SHIPMENT_TRACKING_TYPES.MBL_NUMBER,
    label: TRACKING_TYPE.MBOL,
    requiresAdvancedTracking: true,
  },
  {
    value: SHIPMENT_TRACKING_TYPES.BOOKING_NUMBER,
    label: 'Booking number',
    requiresAdvancedTracking: true,
  },
];

export const getShipmentTypeOptionsForIntermodal = (canAccessAdvancedTracking: boolean): OptionGroup[] => {
  const options = INTERMODAL_SHIPMENT_TYPE_OPTIONS.map((option) => {
    if (option.requiresAdvancedTracking && !canAccessAdvancedTracking) {
      return {
        ...option,
        label: `${option.label} (Upgrade plan to access)`,
        disabled: true,
      };
    }

    return option;
  });

  return [
    {
      title: '',
      items: options,
    },
  ];
};

export const getCarrierOptions = (carriers: CarrierSearchInterface[]) => {
  return carriers.reduce(
    (groupedCarriers, carrier) => {
      const value = carrier?.tracking?.[0]?.name || '';

      const carrierOption = {
        label: carrier.name,
        value,
        type: carrier.type,
        id: carrier.id,
        tracking: carrier.tracking?.[0],
      };

      if (!(carrier.type in groupedCarriers)) {
        // eslint-disable-next-line no-param-reassign
        groupedCarriers[carrier.type] = [];
      }

      groupedCarriers[carrier.type].push(carrierOption);

      return groupedCarriers;
    },
    {} as Record<SegmentModeType, CarrierOption[]>,
  );
};

export const getMailCarrierOptions = (mailCarriers: MailCarrierModel[]) => {
  return {
    title: 'Carriers',
    items: mailCarriers.map((c) => ({
      label: c.name,
      value: c.name,
      icon: (
        <ImageWithFallback
          className="rounded-sm"
          fallbackSrc="/logos/mail-carriers/missing.png"
          src={c.imageUrl || `/logos/mail-carriers/${c.name}_logo.png`}
          alt={c.name}
          width={24}
          height={24}
        />
      ),
    })),
  };
};

export const VALIDATION_MESSAGES: { [key: string]: { message: string; validator: (value: string) => boolean } } = {
  [SHIPMENT_TRACKING_TYPES.MBL_NUMBER]: {
    message:
      "The given MBOL number doesn't appear to match any known formats and might not be valid. Double check it before submitting your tracking request.",
    validator: validateMbl,
  },
};

export const getUserMessage = (error: string): string => {
  switch (error) {
    case API_ERROR.QUOTA_EXCEEDED:
      return 'You have reached the limit of how many shipments you can add on your plan';
    case API_ERROR.UNAUTHORISED:
      return 'You appear to not be signed in. Please refresh the page and try logging in';
    case API_ERROR.TRACKING_REQUEST_EXISTS:
      return 'A new or pending tracking request already exists for this tracking number';
    default:
      return 'Something went wrong, please check the details and try again';
  }
};

export const getTrackingCodeHint = (type: TrackingNumberType): string => {
  switch (type) {
    case SHIPMENT_TRACKING_TYPES.CONTAINER_NUMBER:
      return 'The Container ID is the Carrier Code + the 7 digit Container Number';
    case SHIPMENT_TRACKING_TYPES.MBL_NUMBER:
      return 'This is the Master Bill of Lading provided by your carrier or freight forwarder';
    case SHIPMENT_TRACKING_TYPES.AWB_NUMBER:
      return 'The Airway Bill Number (AWB) is the 11-digit number the provided by the airline or courier';
    case SHIPMENT_TRACKING_TYPES.BOOKING_NUMBER:
      return 'This is the booking number provided by your carrier or freight forwarder';
    case SHIPMENT_TRACKING_TYPES.PARCEL_NUMBER:
      return 'This is the parcel number provided by your carrier';
    default:
      return '';
  }
};

export const getPlaceholderText = (type: TrackingNumberType): string => {
  switch (type) {
    case SHIPMENT_TRACKING_TYPES.CONTAINER_NUMBER:
      return 'e.g. MSDU1234567';
    case SHIPMENT_TRACKING_TYPES.MBL_NUMBER:
      return 'e.g. MSMU123456789';
    case SHIPMENT_TRACKING_TYPES.AWB_NUMBER:
      return 'e.g. 12345678905';
    case SHIPMENT_TRACKING_TYPES.BOOKING_NUMBER:
      return 'e.g. E012345678';
    case SHIPMENT_TRACKING_TYPES.PARCEL_NUMBER:
      return 'e.g. 12345678905';
    default:
      return '';
  }
};
