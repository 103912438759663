import Image, { ImageProps } from 'next/image';
import { useEffect, useState } from 'react';

export interface ImageWithFallbackProps extends ImageProps {
  fallbackSrc: string;
}

const ImageWithFallback = ({ fallbackSrc, ...props }: ImageWithFallbackProps) => {
  const [src, setSrc] = useState(props.src);
  const isDefaultImage = typeof src === 'string' && src.startsWith('/');

  const handleError = () => {
    setSrc(fallbackSrc);
  };

  useEffect(() => {
    setSrc(props.src);
  }, [props.src]);

  return <Image {...props} src={src} alt={props.alt} onError={handleError} unoptimized={!isDefaultImage} />;
};

export { ImageWithFallback };
