import { Container, Package } from 'lucide-react';

import { CARGO_TYPES, MODE_FCL, MODE_WATER } from '@/lib/constants';
import { CargoOption } from '@/types';
import { SegmentModeType } from '@/types/api-types';

export const getCargoOptionSelectionString = (cargo?: CargoOption) => {
  let string = 'Cargo';
  let icon = Package;

  const { cargoType, containerSize, weight } = cargo || {};

  if (cargoType && cargoType === CARGO_TYPES.CONTAINER) {
    string = `${MODE_FCL}, ${containerSize}ft`;
    icon = Container;
  }
  if (cargoType && cargoType === CARGO_TYPES.ITEM) {
    string = `Cargo, ${weight?.value}${weight?.unit}`;
  }

  return {
    string,
    icon,
  };
};

export const getContainerGeneralUnit = (size: string) => {
  switch (size) {
    case '20':
    case '20ft':
    case "20'":
    case 'DC20':
      return 'TEU';
    case '40':
    case '40ft':
    case "40'":
    case 'DC40':
      return 'FEU';
    default:
      return size;
  }
};

export const getCargoRatePerUnit = (mode: SegmentModeType, cargoSize?: string, shortened?: boolean) => {
  const delimiter = shortened ? '/' : 'per';
  return mode === MODE_WATER ? `${delimiter} ${cargoSize || 'CONT'}` : `${delimiter} 100kg`;
};
