import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useState } from 'react';

import { Button, ButtonProps } from '@/components/button';
import { fluentProvider } from '@/lib/api/providers/fluent';
import { getStripeClient } from '@/lib/api/providers/stripe/client';
import {
  COOKIE_REDIRECT_URL,
  CREATE_ACCOUNT_QUERY_STRING,
  SHOULD_CREATE_ACCOUNT,
  SIGN_IN_PAGE_URL,
} from '@/lib/constants';
import { SubscriptionPlan } from '@/types';
import { getUrlOrigin } from '@/utils/helpers/urls';
import { cn } from '@/utils/styles';
import { checkIsFreePlan, getPlanPrice } from '@/utils/subscriptions';
import { trackUser } from '@/utils/tracking';

interface Props {
  plan: SubscriptionPlan;
  buttonText?: string;
  buttonDisabled?: boolean;
  fullWidth?: boolean;
  showArrow?: boolean;
  annualPriceSelected?: boolean;
  subscriptionsEnabled?: boolean;
  variant?: ButtonProps['variant'];
}

const SubscribeButton = ({
  plan,
  buttonText = 'Select Plan',
  buttonDisabled = false,
  fullWidth,
  showArrow = false,
  annualPriceSelected = false,
  subscriptionsEnabled = true,
  variant = 'primaryLight',
}: Props) => {
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);
  const router = useRouter();

  const { data: session } = useSession();

  const callbackUrl = `${getUrlOrigin()}`;
  const isFreePlan = checkIsFreePlan(plan);
  const selectedPrice = getPlanPrice(plan, annualPriceSelected);

  // Plan upgrading is disabled
  const canSelectPlan = isFreePlan || subscriptionsEnabled;
  // Button is disable if it is not active
  const buttonIsDisabled = buttonDisabled || !canSelectPlan;

  const handleOnClick = () => {
    setButtonIsLoading(true);

    if (!session) {
      // user is not logged in
      // Redirect to Sign in
      handleCreateAccount();
      // If the user didn't select a Free plan track the conversion
      if (!isFreePlan) {
        handleSubscriptionInitiatedEvent(plan);
      }
    } else if (canSelectPlan && !isFreePlan) {
      // user is logged in init a Stripe Checkout
      // only if the plan is active and its not a free plan
      handleSubscribeToPlan();
      // Event Tracking
      handleSubscriptionInitiatedEvent(plan);
    } else {
      // Finish up, redirect user back to where they came from
      redirectUser();
    }
  };

  const redirectUser = () => {
    const redirectUrl = Cookies.get(COOKIE_REDIRECT_URL) || '/';
    trackUser.event('Subscribe to Plan (Free)', { plan: plan.name });
    router.push(redirectUrl);
  };

  const handleSubscribeToPlan = async () => {
    if (!selectedPrice) return;

    trackUser.event('Subscribe To Plan (Paid)', {
      location: 'Plan Listing',
      plan: plan.name,
    });

    try {
      const { stripeSessionId } = await fluentProvider.createStripeCheckoutLink(selectedPrice);
      // Redirect to user Stripe checkout
      const stripeClient = await getStripeClient();
      stripeClient?.redirectToCheckout({ sessionId: stripeSessionId });
    } catch (error) {
      console.log(error);
    } finally {
      setButtonIsLoading(false);
    }
  };

  const handleCreateAccount = () => {
    trackUser.event('Go to: Create Account', {
      location: 'Plan Listing',
      plan: plan.name,
    });

    router.push(
      `${SIGN_IN_PAGE_URL}?callbackUrl=${encodeURIComponent(
        callbackUrl as string,
      )}&${CREATE_ACCOUNT_QUERY_STRING}=${SHOULD_CREATE_ACCOUNT}`,
    );
  };

  const handleSubscriptionInitiatedEvent = (selectedPlan: SubscriptionPlan) => {
    // GA conversion event
    trackUser.conversion({
      category: 'Subscription',
      action: 'Initiated',
      label: selectedPlan.name,
    });
    // Posthog
    trackUser.event('Subscription (Initiated)', {
      plan: plan.name,
    });
  };

  return (
    <Button
      variant={variant}
      onClick={() => handleOnClick()}
      disabled={buttonIsDisabled || buttonIsLoading}
      isLoading={buttonIsLoading}
      className={cn('text-baseSm', {
        'w-full': fullWidth,
      })}
      iconRight={showArrow ? 'arrow-right-alt' : ''}
    >
      {canSelectPlan ? buttonText : 'Coming soon'}
    </Button>
  );
};

export { SubscribeButton };
