'use client';

import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isFunction } from 'lodash';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { Controller, FieldErrors, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/button';
import { TextInput } from '@/components/inputs';
import { ComboBox } from '@/components/ui/inputs/combobox';
import { Select } from '@/components/ui/inputs/select';
import { UserMessage } from '@/components/ui/user-message';
import { useCarriers } from '@/hooks/use-carriers';
import {
  MODE_INTERMODAL,
  MODE_WATER,
  SHIPMENT_FORM_FIELDS,
  SHIPMENT_TRACKING_TYPES,
  USER_ACCESS_FEATURES,
} from '@/lib/constants';
import { CreateTrackingRequestBody, TrackingNumberType } from '@/lib/models/shipment/types';
import { OptionGroup, OptionItem, ShipmentFormInputsInterface } from '@/types';
import { canAccessFeature } from '@/utils/auth';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import {
  getCarrierOptions,
  getPlaceholderText,
  getShipmentTypeOptionsForIntermodal,
  getTrackingCodeHint,
  getUserMessage,
  shipmentCreateFormIntermodalValidationSchema,
  VALIDATION_MESSAGES,
} from '../utils';

export interface ShipmentFormProps {
  onSubmit: (data: CreateTrackingRequestBody) => Promise<void>;
  errors?: FieldErrors<ShipmentFormInputsInterface>;
  onError?: (hasError: boolean) => void;
  buttonText?: string;
  disableButtonUntilValid?: boolean;
  buttonIcon?: string;
  className?: string;
  trackingNumberType?: string;
  trackingNumber?: string;
  carrier?: string;
}

const ShipmentFormIntermodal = ({
  onSubmit,
  errors: errorsProp,
  onError,
  buttonText = 'Submit',
  disableButtonUntilValid,
  buttonIcon,
  className,
  trackingNumberType,
  trackingNumber,
  carrier,
}: ShipmentFormProps) => {
  const { data: session } = useSession();
  const { carriers, error: carriersError } = useCarriers({ hasTracking: true });

  const canAccessAdvancedTracking = canAccessFeature(USER_ACCESS_FEATURES.ADVANCED_TRACKING, session);

  const [carrierOptions, setCarrierOptions] = useState<OptionGroup[]>([]);

  const {
    handleSubmit,
    control,
    setError,
    watch,
    reset,
    getValues,
    clearErrors,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ShipmentFormInputsInterface>({
    resolver: yupResolver(shipmentCreateFormIntermodalValidationSchema),
    errors: errorsProp,
  });

  const trackingNumberHasAValue = getValues(SHIPMENT_FORM_FIELDS.TRACKING_NUMBER);

  const handleShipmentFormSubmit: SubmitHandler<ShipmentFormInputsInterface> = async (data) => {
    if (data) {
      const {
        trackingNumberType: trackingNumberTypeFormData,
        trackingNumber: trackingNumberFormData,
        carrier: carrierFormData,
      } = data;

      const shipment: CreateTrackingRequestBody = {
        type: MODE_INTERMODAL,
        [trackingNumberTypeFormData]: trackingNumberFormData,
        oceanLine: carrierFormData,
      };

      trackUser.event(`Submit shipment form ${MODE_INTERMODAL}`, { shipment });
      try {
        await onSubmit(shipment);
        // Reset if no errors were returned
        reset({
          [SHIPMENT_FORM_FIELDS.CARRIER]: '',
          [SHIPMENT_FORM_FIELDS.TRACKING_NUMBER]: '',
          [SHIPMENT_FORM_FIELDS.TRACKING_NUMBER_TYPE]: trackingNumberType,
        });
      } catch (e: any) {
        setError('root.serverError', { message: getUserMessage(e.message) });
      }
    }
  };

  useEffect(() => {
    if (carriers) {
      const groupedCarriers = getCarrierOptions(carriers);
      setCarrierOptions([{ title: 'Ocean carriers', items: groupedCarriers[MODE_WATER] }]);
    }
  }, [carriers]);

  useEffect(() => {
    if (carriersError) {
      setError(SHIPMENT_FORM_FIELDS.CARRIER, { message: 'Error fetching carriers' });
    }
  }, [carriersError]);

  useEffect(() => {
    if (isFunction(onError)) {
      onError(!isEmpty(errors));
    }
  }, [errors, onError]);

  const shipmentReferenceType = watch(SHIPMENT_FORM_FIELDS.TRACKING_NUMBER_TYPE) as TrackingNumberType;
  const shipmentReferenceValue = watch(SHIPMENT_FORM_FIELDS.TRACKING_NUMBER);
  const trackingNumberHintMessage = getTrackingCodeHint(shipmentReferenceType);
  const placeholderText = getPlaceholderText(shipmentReferenceType);

  const validationMessage = (() => {
    if (!trackingNumberHasAValue) return null;

    const { message, validator } = VALIDATION_MESSAGES[shipmentReferenceType] || {};
    if (validator && message) {
      return !validator(shipmentReferenceValue) ? message : null;
    }
    return null;
  })();

  const handleCarrierChange = (option: OptionItem) => {
    setValue(SHIPMENT_FORM_FIELDS.CARRIER, option.value);

    setValue(SHIPMENT_FORM_FIELDS.TRACKING_NUMBER_TYPE, SHIPMENT_TRACKING_TYPES.CONTAINER_NUMBER);
  };

  const { base, list, inputWrapper, buttonWrapper, button, error, typeSelect } = shipmentCreateFormStyles();

  return (
    <>
      {errors.root?.serverError ? (
        <UserMessage body={errors.root.serverError.message} heading="Error" variant="error" className={error()} />
      ) : null}
      <form className={base({ className })} onSubmit={handleSubmit(handleShipmentFormSubmit)}>
        <ol className={list()}>
          <li>
            <Controller
              control={control}
              name={SHIPMENT_FORM_FIELDS.CARRIER}
              defaultValue={carrier}
              render={({ field: { value, ...field } }) => {
                return (
                  <ComboBox
                    className={typeSelect()}
                    selected={value}
                    id={field.name}
                    errors={errors}
                    label="Carrier"
                    placeholder="Choose a carrier"
                    tooltipContent="If you know the carrier, it may assist with finding your shipment quicker"
                    options={carrierOptions}
                    onSelect={(option) => {
                      handleCarrierChange(option);
                      clearErrors(SHIPMENT_FORM_FIELDS.CARRIER);
                    }}
                    disabled={isSubmitting}
                    isLabelOptional
                  />
                );
              }}
            />
          </li>
          <li>
            <Controller
              control={control}
              name={SHIPMENT_FORM_FIELDS.TRACKING_NUMBER_TYPE}
              defaultValue={trackingNumberType}
              render={({ field: { onChange, value, ...field } }) => {
                return (
                  <Select
                    className={typeSelect()}
                    selected={value}
                    id={field.name}
                    errors={errors}
                    label="Tracking number type"
                    options={getShipmentTypeOptionsForIntermodal(canAccessAdvancedTracking)}
                    onSelect={(option) => {
                      onChange(option.value);
                      clearErrors(SHIPMENT_FORM_FIELDS.TRACKING_NUMBER);
                    }}
                    disabled={isSubmitting}
                  />
                );
              }}
            />
          </li>
          <li>
            <div className={inputWrapper()}>
              <Controller
                control={control}
                name={SHIPMENT_FORM_FIELDS.TRACKING_NUMBER}
                defaultValue={trackingNumber}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      disabled={isSubmitting}
                      errors={errors}
                      label="Tracking number"
                      placeholder={placeholderText}
                      instructions={trackingNumberHintMessage}
                    />
                  );
                }}
              />
            </div>
          </li>
        </ol>

        {validationMessage ? (
          <UserMessage body={validationMessage} heading="Validation check" variant="info" noIcon className={error()} />
        ) : null}
        <div className={buttonWrapper()}>
          <Button
            className={button()}
            disabled={isSubmitting || (disableButtonUntilValid && !isValid)}
            isLoading={isSubmitting}
            iconLeft={buttonIcon}
          >
            {buttonText}
          </Button>
        </div>
      </form>
    </>
  );
};

export const shipmentCreateFormStyles = tv({
  slots: {
    base: 'w-full',
    list: 'mb-4 flex flex-col space-y-4',
    button: 'w-full',
    buttonWrapper: 'flex w-full justify-end',
    inputWrapper: 'relative flex-1',
    hintText: 'mb-0 mt-1 px-2 text-sm text-text-secondary',
    typeSelect: 'min-w-32',
    error: '!mb-5 !mt-2',
  },
});

export { ShipmentFormIntermodal };
